<template>
    <div>
        <div class="login_header">
            桐尘预约-分销中心
        </div>
        <van-form @submit="onSubmit" class="login_box">
            <van-cell-group inset>
                <van-field v-model="account" name="phone" label="手机号码：" placeholder="请填写手机号码"
                    :rules="[{ required: true, message: '请填写手机号码' }]" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    登录
                </van-button>
            </div>
        </van-form>
        <div class="icp">
            <a href="http://beian.miit.gov.cn/" rel="noreferrer" target="_blank">渝ICP备2023007611号-1</a>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { AgentLogin } from "../../api/agent";
import { useRouter } from "vue-router";
export default {
    setup() {
        const account = ref('');
        const router = useRouter();
        const onSubmit = (values) => {
            if(values){
                AgentLogin(values).then((res)=>{
                    if(res){
                        localStorage.setItem('agentInfo',JSON.stringify(res.item))
                        localStorage.setItem('agentPhone',res.item.phone)
                        // 跳转首页
                        router.push({ name: "Home" });
                    }
                })
            }
        };

        return {
            account,
            onSubmit,
        };
    },
}
</script>
<style>
.login_header {
    padding: 25px;
    text-align: center;
    font-size: 18px;
    margin-top: 15%;
}

.login_box {
    padding: 25px;
}
.icp {
  padding: 25px;
  text-align: center;
  color: #4a4a4a;
  font-size: 12px;
}

.icp a {
  color: #4a4a4a !important;
}
</style>